.ant-form-item-label {
  font-weight: 500;
}
.gs-label {
  color: themeColor(primary);
  //margin-bottom: 8px;
  font-size: 16px;
  margin-left: 10px;
  font-weight: 600;
  display: block;
}

.gs-btn-icon {
  width: 14px;
  height: 14px;
  position: relative;
  top: 2px;
}
.gs-fw-700 {
  font-weight: 700;
}
.gs-fw-600 {
  font-weight: 600;
}
.gs-fw-500 {
  font-weight: 500;
}
.gs-fw-400 {
  font-weight: 400;
}
.gs-fw-300 {
  font-weight: 300;
}
.gs-fs-12 {
  font-size: 12px;
  line-height: 16px;
}
.gs-fs-14 {
  font-size: 14px;
  line-height: 20px;
}
.gs-fs-16 {
  font-size: 16px;
  line-height: 24px;
}
.gs-fs-20 {
  font-size: 20px;
  line-height: 24px;
}
.gs-fs-24 {
  font-size: 24px;
  line-height: 28px;
}
.gs-fs-28 {
  font-size: 28px;
  line-height: 32px;
}
.gs-fs-32 {
  font-size: 32px;
  line-height: 36px;
}
.gs-c-main {
  color: var(--color-text);
}
.gs-c-secondary {
  color: var(--color-text-secondary);
}
.gs-c-tertiary {
  color: var(--color-text-tertiary);
}
.gs-c-primary {
  color: var(--color-primary);
}
.gs-mr {
  margin-right: var(--margin);
}
.gs-mr-sm {
  margin-right: var(--margin-sm);
}
.gs-mr-xs {
  margin-right: var(--margin-xs);
}
.gs-mr-xxs {
  margin-right: var(--margin-xxs);
}
.gs-mr-md {
  margin-right: var(--margin-md);
}
.gs-mr-lg {
  margin-right: var(--margin-lg);
}
.gs-mr-xl {
  margin-right: var(--margin-xl);
}
.gs-ml {
  margin-left: var(--margin);
}
.gs-ml-sm {
  margin-left: var(--margin-sm);
}
.gs-ml-xs {
  margin-left: var(--margin-xs);
}
.gs-ml-xxs {
  margin-left: var(--margin-xxs);
}
.gs-mb-xl {
  margin-bottom: var(--margin-xl);
}
.gs-mb-md {
  margin-bottom: var(--margin-md);
}
.gs-mb-lg {
  margin-bottom: var(--margin-lg);
}
.gs-mb {
  margin-bottom: var(--margin);
}
.gs-mb-sm {
  margin-bottom: var(--margin-sm);
}
.gs-mb-xs {
  margin-bottom: var(--margin-xs);
}
.gs-mb-xxs {
  margin-bottom: var(--margin-xxs);
}
.gs-mt-xxl {
  margin-top: var(--margin-xxl);
}
.gs-mt-xl {
  margin-top: var(--margin-xl);
}
.gs-mt-lg {
  margin-top: var(--margin-lg);
}
.gs-mt {
  margin-top: var(--margin);
}
.gs-mt-sm {
  margin-top: var(--margin-sm);
}
.gs-mt-xs {
  margin-top: var(--margin-xs);
}
.gs-mt-xxs {
  margin-top: var(--margin-xxs);
}
.gs-m {
  margin: var(--margin);
}
.gs-mv-sm {
  margin-top: var(--margin-sm);
  margin-bottom: var(--margin-sm);
}
.gs-mv-xs {
  margin-top: var(--margin-xs);
  margin-bottom: var(--margin-xs);
}
.gs-mv-xxs {
  margin-top: var(--margin-xxs);
  margin-bottom: var(--margin-xxs);
}
.gs-mh-xxs {
  margin-right: var(--margin-xxs);
  margin-left: var(--margin-xxs);
}
.gs-p {
  padding: var(--padding);
}
.gs-p-sm {
  padding: var(--padding-sm);
}
.gs-p-xs {
  padding: var(--padding-xs);
}
.gs-p-xxs {
  padding: var(--padding-xxs);
}
.gs-pt {
  padding-top: var(--padding);
}
.gs-pt-sm {
  padding-top: var(--padding-sm);
}
.gs-pt-xs {
  padding-top: var(--padding-xs);
}
.gs-pt-xxs {
  padding-top: var(--padding-xxs);
}
.gs-pl-0 {
  padding-left: 0;
}
.gs-pb {
  padding-bottom: var(--padding);
}
.gs-pb-sm {
  padding-bottom: var(--padding-sm);
}
.gs-pb-xs {
  padding-bottom: var(--padding-xs);
}
.gs-pb-xxs {
  padding-bottom: var(--padding-xxs);
}
.gs-ph-lg {
  padding-right: var(--padding-lg);
  padding-left: var(--padding-lg);
}
.gs-ph-md {
  padding-right: var(--padding-md);
  padding-left: var(--padding-md);
}
.gs-ph {
  padding-right: var(--padding);
  padding-left: var(--padding);
}
.gs-ph-sm {
  padding-right: var(--padding-sm);
  padding-left: var(--padding-sm);
}
.gs-ph-xs {
  padding-right: var(--padding-xs);
  padding-left: var(--padding-xs);
}
.gs-ph-xxs {
  padding-right: var(--padding-xxs);
  padding-left: var(--padding-xxs);
}
.gs-ph-0 {
  padding-right: 0;
  padding-left: 0;
}
.gs-pv-xs {
  padding-top: var(--padding-xs);
  padding-bottom: var(--padding-xs);
}
.gs-pv-xxs {
  padding-top: var(--padding-xxs);
  padding-bottom: var(--padding-xxs);
}
.gs-pv-sm {
  padding-top: var(--padding-sm);
  padding-bottom: var(--padding-sm);
}
.gs-pv {
  padding-top: var(--padding);
  padding-bottom: var(--padding);
}
.gs-pv-md {
  padding-top: var(--padding-md);
  padding-bottom: var(--padding-md);
}
.gs-va-top {
  vertical-align: top;
}
.gs-w-100p {
  width: 100%;
}
.gs-w-0 {
  width: 0;
}
.gs-min-w-0 {
  min-width: 0;
}
.gs-min-h-0 {
  min-height: 0;
}
.gs-block {
  display: block;
}
.gs-grow {
  flex-grow: 1;
}
.gs-no-shrink {
  flex-shrink: 0;
}
.gs-relative {
  position: relative;
}
.gs-absolute {
  position: absolute;
}
.gs-fixed {
  position: fixed;
}
.gs-break-word {
  word-break: break-word;
}
.gs-pre-wrap {
  white-space: pre-wrap;
}
.gs-line-clamp-2 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  line-clamp: 2;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.gs-line-clamp-3 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  line-clamp: 3;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}
.gs-line-clamp-4 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  line-clamp: 4;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
}

.gs-scroll {
  overflow: auto;
  &::-webkit-scrollbar{
    width: 5px;
    height: 5px;
  }
  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #F0F3F6;;
    border-radius: 10px;
  }
  &::-webkit-scrollbar-thumb {
    background:#99ACC2;
    border-radius: 10px;
  }
  &::-webkit-scrollbar-thumb:hover {
    background:#8495a8;
  }
}

// https://stackoverflow.com/questions/44793453/how-do-i-add-a-top-and-bottom-shadow-while-scrolling-but-only-when-needed
.gs-scroll-vertical-shadows {
  background:
    linear-gradient(white 30%, rgba(255, 255, 255, 0)), linear-gradient(rgba(255, 255, 255, 0), white 70%) 0 100%,
    radial-gradient(farthest-side at 50% 0, rgba(0, 0, 0, .2), rgba(0, 0, 0, 0)), radial-gradient(farthest-side at 50% 100%, rgba(0, 0, 0, .2), rgba(0, 0, 0, 0)) 0 100%;
  background-repeat: no-repeat;
  background-size: 100% 40px, 100% 40px, 100% 14px, 100% 14px;
  background-attachment: local, local, scroll, scroll;
}

.gs-border {
  border-style: var(--line-type);
  border-width: var(--line-width);
  border-color: var(--color-border);
  border-radius: var(--border-radius);
}

.gs-input-border {
  border-style: var(--line-type);
  border-width: var(--line-width);
  border-color: var(--color-border);
  border-radius: var(--border-radius);

  &:hover {
    border-color: var(--input-hover-border-color);
  }

  &:focus, &:focus-within {
    border-color: var(--input-active-border-color);
    box-shadow: var(--input-active-shadow);
    outline: 0;
  }
}

.gs-text {
  //font: Text();
  //color: themeColor(primary);
  //cursor: default;
  //===============================================================
  // Not sorted yet
  //===============================================================
  &._paragraph {
    margin-bottom: 6px;
  }

  &._paragraph_l_mg {
    margin-bottom: 20px;
  }

  &._uppercase {
    text-transform: uppercase;
  }
  &._overflow {
    display: block;
    overflow: hidden;
    white-space: nowrap;
    -ms-text-overflow: ellipsis;
    text-overflow: ellipsis;
    max-width: 100%;
  }
  &._overflow-90 {
    display: block;
    overflow: hidden;
    white-space: nowrap;
    -ms-text-overflow: ellipsis;
    text-overflow: ellipsis;
    max-width: 90%;
  }
  &._center {
    text-align: center;
  }
  &._in-select {
    height: 43px;
    line-height: 41px;
  }
  &._nowrap {
    white-space: nowrap;
  }
  &._dashed {
    border-bottom: 1px dashed currentColor;
  }
  &._italic {
    font-style: italic;
  }
  &._btn {
    display: inline-flex;
    color: white !important;
    padding: 0 6px;
    border-radius: 20px;
    background-color: darken(themeColor(default), 5%);
  }
  &._disabled {
    color: themeColor(gray) !important;
    //cursor: not-allowed !important;
  }
  &._one-row {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: block;
  }
  &._inline {
    margin-left: 4px;
  }
  &._editable {
    border-bottom: 1px dashed transparent;
    &:hover {
      cursor: pointer;
      color: themeColor(success) !important;
      border-bottom-color: themeColor(success);
    }
  }
  &._with_line {
    text-decoration-line: underline;
  }
  &._dropdown {
    cursor: pointer;

    &::after {
      content: '';
      display: inline-block;
      vertical-align: middle;
      margin-left: 6px;
      width: 0;
      height: 0;
      border-top: 3px solid currentColor;
      border-left: 3px solid transparent;
      border-right: 3px solid transparent;
    }
  }
  &._tt-none {
    text-transform: none;
  }

  //===============================================================
  // Sizes
  //===============================================================
  &._xs, %fs-xs {
    font-size: fontSize(xSmall);
  }

  &._sm, %fs-sm {
    font-size: fontSize(small);
  }
  &._md, %fs-md{
    font-size: fontSize(medium);
  }
  &._lg, %fs-lg {
    font-size: fontSize(large);
  }
  &._xlg, %fs-xlg {
    font-size: fontSize(xLarge);
  }
  &._exlg, %fs-exlg {
    font-size: fontSize(xxLarge);
  }

  //===============================================================
  // Weights
  //===============================================================
  &._thin {
    font-weight: fontWeight(thin);
  }
  &._light {
    font-weight: fontWeight(light);
  }
  &._regular {
    font-weight: fontWeight(regular);
  }
  &._medium {
    font-weight: fontWeight(medium);
  }
  &._bold {
    font-weight: fontWeight(bold);
  }

  //===============================================================
  // Alignment
  //===============================================================
  &._a-center {
    text-align: center;
  }
  &._a-left {
    text-align: left;
  }
  &._a-right {
    text-align: right;
  }

  //===============================================================
  // Theme colors
  //===============================================================
  &._light {
    color: lighten(themeColor(main), 25%);
  }
  &._lighter {
    color: lighten(themeColor(main), 35%);
  }
  &._dark {
    color: darken(themeColor(main), 20%);
  }
  &._darken {
    color: darken(themeColor(main), 35%);
  }
  &._steel {
    color: themeColor(steel);
  }
  &._action{
    color: themeColor(action);
  }
  &._success {
    color: themeColor(success);
  }
  &._danger,
  &._error {
    color: themeColor(danger);
  }
  &._warning {
    color: themeColor(warning);
  }
  &._info {
    color: themeColor(info);
  }
  &._primary {
    color: themeColor(second);
  }
  &._c_primary {
    color: themeColor(primary);
  }
  &._white {
    color: white;
  }
  &._orange {
    color: themeColor(orange);
  }

  &._flow {
    color: themeColor(flowName);
  }

  &-dim,
  &._dim {
    color: themeColor(text-dim);
  }
  //===============================================================
  // Link
  //===============================================================
  &._link {
    color: var(--color-link);
    cursor: pointer;

    // @include gs-hover {
    //   color: themeColor(main);
    // }

    // @include gs-active {
    //   color: themeColor(main);
    // }
  }

  &._blue {
    color: themeColor(main);
  }
}

// @media (max-width: 800px) {
//   .gs-text {
//     font: Text(light, small);
//     &._xs, %fs-xs {
//       font-size: fontSize(xSmall) - 1px;
//     }
//     &._sm, %fs-sm {
//       font-size: fontSize(xSmall);
//     }
//     &._md, %fs-md{
//       font-size: fontSize(small);
//     }
//     &._lg, %fs-lg {
//       font-size: fontSize(medium);
//     }
//     &._xlg, %fs-xlg {
//       font-size: fontSize(large);
//     }
//     &._exlg, %fs-exlg {
//       font-size: fontSize(xLarge);
//     }
//   }
// }
